import React, { useState } from 'react';
import MenuAppBar from './MenuAppBar';
import SEO from './Seo';
import { SidebarMenu } from './SidebarMenu';

const Layout = ({ children, heading, title }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <SEO title={title} />
            <MenuAppBar heading={heading} openDrawer={setOpen} />
            <SidebarMenu open={open} setOpen={setOpen} />
            <main>{children}</main>
        </>
    );
};

export default Layout;
