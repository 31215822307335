import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title }) => {
    return (
        <Helmet title={title || 'Covid-19 Portal'}>
            <link
                rel='stylesheet'
                href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
            />
            <link
                rel='stylesheet'
                href='https://fonts.googleapis.com/icon?family=Material+Icons'
            />
            <meta
                name='viewport'
                content='minimum-scale=1, initial-scale=1, width=device-width'
            />
        </Helmet>
    );
};

export default SEO;
