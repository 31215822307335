import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'gatsby';
import { isAuthenticated } from '../utils/auth';

const MenuOptions = [
    { label: 'Home', icon: <HomeIcon />, route: '/' },
    {
        label: 'Account',
        icon: <AccountIcon />,
        route: '/account',
        protected: true,
    },
    {
        label: 'Surveys',
        icon: <AssignmentIcon />,
        route: '/account/completed-surveys',
        protected: true,
    },
];
export const SidebarMenu = ({ open, setOpen }) => {
    const isAuthed = isAuthenticated();
    return (
        <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
            <List style={{ width: '250px' }}>
                {MenuOptions.filter((option) =>
                    option.protected ? isAuthed : true
                ).map((option) => (
                    <ListItem
                        button
                        key={option.label}
                        component={Link}
                        to={option.route}
                    >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.label} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};
